// Our main CSS
import '../css/app.css'

/**
 * Vue is ready for you, if you need it.
 */

/**
 * The following block of code automatically registers your Vue components.
 * It will recursively scan this directory for the Vue components and
 * automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 *
 * Feel free to remove this block if you don't like black magic fuckery,
 * or you want to register your Vue components manually.
 */
/*
 *** https://v3.vuejs.org/
 */
// interface ImportedModule {
//   name: string
//   default: object
// }
// const vueSingleFileComponents = import.meta.glob<ImportedModule>('./**/*.vue', {
//   eager: true
// })

// import { createApp } from 'vue'
// const app = createApp({})

// for (const path in vueSingleFileComponents) {
//   const importedModule = vueSingleFileComponents[String(path)]
//   const name = path.split('/').pop()?.split('.')[0]
//   if (name !== undefined && typeof name === 'string') {
//     app.component(name, importedModule.default)
//   }
// }
// app.mount('#vue-app')

/**
OR:

Load a js-module dynamically, if the parent element exists, in this example, a vue component. (The js-file wont load if html element with id #ExampleVueComponentWrapper does not exists)
*/

/**
const ExampleVueComponentWrapper = document.getElementById(
  'ExampleVueComponentWrapper'
)
if (ExampleVueComponentWrapper && ExampleVueComponentWrapper !== null) {
  import('./ExampleVueComponent/index.js')
    .then((module) => {
      module.default(ExampleVueComponentWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}
*/

/**
 * 🦄: Now do your magic.
 */

const NewsletterFormWrapper = document.getElementById('NewsletterFormWrapper')
if (NewsletterFormWrapper && NewsletterFormWrapper !== null) {
  import('./NewsletterForm/index.js')
    .then((module) => {
      module.default(NewsletterFormWrapper as HTMLElement)
    })
    .catch((err) => {
      console.log(err)
      // main.textContent = err.message;
    })
}

if (document.querySelectorAll('.accordion-block').length > 0) {
  import('./accordion').then((module) => {
    module.default()
  })
}

if (document.querySelector('.slider')) {
  import('./slider').then((module) => {
    module.default()
  })
}

if (document.querySelector('.image-slider')) {
  import('./image-slider').then((module) => {
    module.default()
  })
}

// Frontpage animation
function frontpageAnimation() {
  setTimeout(() => {
    const element = document.querySelector('.frontpage-animation')
    if (element) element.classList.add('invisible')
  }, 7000)
}
frontpageAnimation()

// Menu modal
const modalMenuItems = document.querySelectorAll('#menu-main-menu-side a')
if (modalMenuItems.length > 0) {
  modalMenuItems.forEach((item) => {
    item.setAttribute('tabindex', '-1')
  })
}

const modalBtn = document.querySelector('.modal-button')
const modalOverlay = document.querySelector('.modal-overlay')
const menuModal = document.querySelector('.menu-modal')
const modalCloseBtns = document.querySelectorAll('.modal-close-button')

function modalAriaExpanded() {
  const modalBtnExpanded = modalBtn?.getAttribute('aria-expanded')

  modalCloseBtns.forEach((btn) => {
    const modalCloseBtnExpanded = btn.getAttribute('aria-expanded')
    modalCloseBtnExpanded === 'true'
      ? btn.setAttribute('aria-expanded', 'false')
      : btn.setAttribute('aria-expanded', 'true')
  })

  modalBtnExpanded === 'true'
    ? modalBtn?.setAttribute('aria-expanded', 'false')
    : modalBtn?.setAttribute('aria-expanded', 'true')
}

if (modalBtn) {
  modalBtn.addEventListener('click', () => {
    if (modalMenuItems.length > 0) {
      modalMenuItems.forEach((item) => {
        item.setAttribute('tabindex', '0')
      })
    }

    if (modalCloseBtns.length > 0) {
      modalCloseBtns.forEach((btn) => {
        btn.setAttribute('tabindex', '0')
      })
    }

    modalOverlay?.classList.remove('hidden')
    setTimeout(() => {
      modalOverlay?.classList.remove('opacity-0')
      modalOverlay?.classList.add('opacity-50')
    }, 50)

    menuModal?.classList.remove('w-0')
    menuModal?.classList.add('w-full')

    modalAriaExpanded()
  })
}

if (modalCloseBtns.length > 0) {
  modalCloseBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      closeModal()
    })
  })
}

if (modalOverlay) {
  modalOverlay.addEventListener('click', (event) => {
    if (event.target === modalOverlay) {
      closeModal()
    }
  })
}

function closeModal() {
  if (modalMenuItems.length > 0) {
    modalMenuItems.forEach((item) => {
      item.setAttribute('tabindex', '-1')
    })
  }

  modalCloseBtns.forEach((btn) => {
    btn.setAttribute('tabindex', '-1')
  })

  modalOverlay?.classList.remove('opacity-50')
  modalOverlay?.classList.add('opacity-0')
  setTimeout(() => {
    modalOverlay?.classList.add('hidden')
  }, 500)

  menuModal?.classList.remove('w-full')
  menuModal?.classList.add('w-0')

  modalAriaExpanded()
}

document.addEventListener('DOMContentLoaded', () => {
  const menuItems: HTMLElement[] = Array.from(
    document.querySelectorAll('.main-menu ul.menu .medium')
  )
  if (menuItems.length > 0) {
    menuItems[0].style.marginTop = '64px'
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const menuItems: HTMLElement[] = Array.from(
    document.querySelectorAll('.main-menu ul.menu .small')
  )
  if (menuItems.length > 0) {
    menuItems[0].style.marginTop = '64px'
  }
})
